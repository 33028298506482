import Login from "./Login"
import Consent from "./Consent"
import DefaultPrompt from "./DefaultPrompt"
import { setFooter } from "shared"

const interactionLoader = async ({ params }) => {
  try {
    const response = await fetch(`/oidc/interaction/${params.session}/session`)
    if (response.status === 400) throw new Response("Failed to find login session", { status: 400 })
    const data = await response.json()

    /** @type {import("shared/pageFooter").FooterItem[]} */
    const uris = []
    if (data.client.tos_uri) uris.push({ element: "Terms of Service", href: data.client.tos_uri })
    if (data.client.policy_uri) uris.push({ element: "Privacy Policy", href: data.client.policy_uri })
    if (uris.length) setFooter(...uris)

    return data
  } catch (err) {
    if (err instanceof SyntaxError) throw new Error()
    console.warn(err)
    throw err
  }
}

/** @type {import("react-router-dom").RouteObject[]} */
export default [{
  path: "login/:session",
  loader: interactionLoader,
  element: <div className="page-container"><Login /></div>
}, {
  path: "consent/:session",
  loader: interactionLoader,
  element: <div className="page-container"><Consent /></div>
}, {
  path: "*/:session",
  loader: interactionLoader,
  element: <div className="page-container"><DefaultPrompt /></div>
}]
