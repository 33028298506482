import { Button, Space } from "antd"
import { Container } from "components"
import { useLoaderData } from "react-router-dom"
import SessionNotFound from "./SessionNotFound"
import SignedInUser from "components/SignedInUser"

/**
 * @typedef {import("components/SignedInUser").UserInfo & { token: String }} EndSessionData
 */

/** @type {import("react").MouseEventHandler<HTMLElement>} e */
const abortBackchannelLogout = async (e) => {
  e.preventDefault() // prevent form submit if js enabled
  await fetch("/oidc/session/end/abort", { method: "POST" })
  const form = /** @type {HTMLFormElement} */ (document.getElementById("op.logoutForm"))
  form.submit() // onclick handler blocks form submit
}

const EndSession = () => {
  const data = /** @type {EndSessionData} */ (useLoaderData())

  return (
    <div className="page-container">
      <Container>
        <Container.Header />
        <Container.Title level={3}>Do you want to sign out from Web-Fuse and all its services?</Container.Title>

        <SignedInUser userinfo={data} allowChangeUser={false} />

        <form id="op.logoutForm" method="POST" action="/oidc/session/end/confirm">
          <input type="hidden" name="xsrf" value={data.token} />

          <Space direction="vertical">
            <Button
              autoFocus block
              type="primary"
              htmlType="submit"
              value="yes" name="logout"
            >
              Yes, sign me out
            </Button>
            <Button
              block
              type="primary"
              htmlType="submit"
              onClick={abortBackchannelLogout}
            >
              No, stay signed in
            </Button>
          </Space>
        </form>
      </Container>
    </div>
  )
}

/** @type {import("react-router-dom").RouteObject} */
export default {
  path: "/session/end",
  loader: async () => {
    try {
      const params = new URL(window.location.href).searchParams

      const response = await fetch("/oidc/session/end", {
        method: "POST",
        redirect: "error",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: params.toString()
      })

      if (response.status !== 200) {
        // not logged in perhaps??
        throw response
      }

      const contentType = response.headers.get("content-type")
      if (contentType.includes("application/json")) {
        const data = await response.json()
        return data
      } else {
        throw new Response("Session expired or logged out")
      }
    } catch (err) {
      console.warn(err)
      throw err
    }
  },
  element: <EndSession />,
  errorElement: <SessionNotFound />
}
