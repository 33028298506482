import { ArrowLeftOutlined } from "@ant-design/icons"
import { Space } from "antd"
import { useParams } from "react-router-dom"

/** @param {{ disabled?: Boolean }} props */
const CancelLogin = ({ disabled }) => {
  const params = useParams()

  return (
    <div style={{ display: "block", marginTop: 12 }}>
      <a href={!disabled && `/oidc/interaction/${params.session}/abort`} style={{ color: "rgb(95, 95, 95)", cursor: disabled && "not-allowed" }}>
        <Space align="baseline" style={{ columnGap: 2 }}>
          <ArrowLeftOutlined />
          <span>Cancel</span>
        </Space>
      </a>
    </div>
  )
}

export default CancelLogin
