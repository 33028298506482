import { Typography } from "antd"
import { Container } from "components"

const EndSessionSuccess = () => {
  return (
    <div className="page-container">
      <Container>
        <Container.Header />
        <Container.Title level={4}>You have been signed out successfully.</Container.Title>

        <Typography.Paragraph>
          It's safe to close this page now.
        </Typography.Paragraph>

        {/* maybe show some login options here or option to return to main site */}
      </Container>
    </div>
  )
}

const EndSessionCancelled = () => {
  return (
    <div className="page-container">
      <Container>
        <Container.Header />
        <Container.Title>Sign out cancelled</Container.Title>

        <Typography.Paragraph>
          Your session is still active.
        </Typography.Paragraph>

        {/* button to return or something? */}
      </Container>
    </div>
  )
}

/** @type {import("react-router-dom").RouteObject} */
export default {
  path: "/session/end/success",
  element: <EndSessionSuccess />
}
