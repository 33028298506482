import { Space, Card, Typography } from "antd"

/* images should be loaded from web-fuse.com/public */

/** @param {import("react").PropsWithChildren} props */
const Container = ({ children }) => (
  <Card className="card-container">
    { children }
  </Card>
)

Container.Header = () => (
  <Space style={{ display: "flex" }}>
    <img src="/logo/logo-orange-black.svg" height="24px" width="auto" />
    <img src="/logo/web-fuse-text.svg" height="12px" width="auto" />
  </Space>
)

/** @param {import("antd/es/typography/Title").TitleProps} props */
Container.Title = (props) => (
  <Typography.Title
    level={2}
    {...props}
    style={Object.assign({ marginTop: 4 }, props.style)}
  />
)

/** @param {import("antd/es/typography/Paragraph").ParagraphProps} props */
Container.Subtitle = (props) => (
  <Typography.Paragraph
    {...props}
    style={Object.assign({
      marginTop: -18,
      marginBottom: 14,
      fontSize: "0.8125rem",
      lineHeight: "20px",
      fontWeight: 400
    }, props.style)}
  />
)

export default Container
