import { Button, Collapse, Space } from "antd"
import { useRouteError } from "react-router-dom"
import { Container } from "../components"

const SessionNotFound = () => {
  /** @type {import("react-router-dom").ErrorResponse} */
  const error = /** @type {any} */ (useRouteError())

  /** @type {import("antd").CollapseProps["items"]} */
  const items = [{
    key: '1',
    label: "Detail",
    children: error.data || "Unknown error"
  }]

  return (
    <div className="page-container">
      <Container>
        <Container.Header />
        <Space direction="vertical">
          <Container.Title>
            Your session expired
          </Container.Title>
          <Button onClick={() => window.history.back()}>
            Return
          </Button>

          <Collapse items={items} ghost size="small" />
        </Space>
      </Container>
    </div>
  )
}

export default SessionNotFound
