import { Typography } from "antd"
import React from "react"
import ReactDOM from 'react-dom/client'

/**
 * @typedef {Object} FooterItem
 * @property {import("react").ReactElement|String} element
 * @property {String} [href]
 */

const root = ReactDOM.createRoot(document.getElementById("footer"))

/** @type {readonly FooterItem[]} */
const defaultItems = Object.freeze([{
  element: `© Web-Fuse ${ new Date().getFullYear() }`
}])


/** @param {FooterItem[]} [items] */
export const setFooter = (...items) => {
  if (!root) return

  const elements = defaultItems.concat(items).map(({ href, element }, i) => {
    if (href) {
      return <Typography.Link href={href} target="_blank" style={{ color: "inherit", fontSize: "inherit" }} key={i}>{ element }</Typography.Link>
    }
    switch (typeof element) {
      case "string":
      case "number":
      case "boolean":
        return <span key={i}>{ element }</span>
      default:
        return React.cloneElement(element, { key: i })
    }
  })

  elements.push(elements.shift())
  root.render(elements)
}
