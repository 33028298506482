import { useRef, useState } from "react"
import { Link, useLoaderData } from "react-router-dom"
import { Container, CancelLogin } from "../../components"
import { Button, Carousel, List, Space, Typography } from "antd"
import { HomeOutlined, MailOutlined, PhoneOutlined, QuestionOutlined, SafetyOutlined, UserOutlined } from "@ant-design/icons"
import SignedInUser from "components/SignedInUser"

/**
 * https://openid.net/specs/openid-connect-basic-1_0.html#Scopes
 * @typedef {"openid"|"profile"|"email"|"address"|"phone"|"offline_access"|String} OidcScope
 *
 * OIDC Core 1.0: https://openid.net/specs/oauth-v2-multiple-response-types-1_0.html#ResponseModes
 * JARM: https://openid.net/specs/openid-financial-api-jarm.html#response-encoding
 * @typedef {"query"|"fragment"|"jwt"|"query.jwt"|"fragment.jwt"|"form_post.jwt"|String} ResponseMode
 *
 * @typedef {"consent_prompt"|"op_scopes_missing"|String} PromptReason
 *
 * @typedef {Object} Client
 * @property {String} client_name
 * @property {String} client_uri
 * @property {String} [initiate_login_uri]
 * @property {String} [policy_uri]
 * @property {String} [tos_uri]
 * @property {true} [firstParty]
 *
 * // https://openid.net/specs/openid-connect-core-1_0.html#AuthRequest
 * @typedef {Object} AuthRequest
 * @property {String} scope
 * @property {"code"|String} response_type
 * @property {String} client_id
 * @property {String} redirect_uri
 * @property {String} [state]
 * @property {ResponseMode} [response_mode]
 * @property {String} [none]
 * @property {"page"|"popup"|"touch"|"wap"} display
 * @property {"none"|"login"|"consent"|"select_account"} [prompt]
 * @property {Number} [max_age]
 * @property {String} [ui_locales]
 * @property {String} [id_token_hint]
 * @property {String} [login_hint]
 * @property {String} [acr_values]
 * @property {String} [code_challenge] PKCE
 * @property {String} [code_challenge_method] PKCE
 *
 * @typedef {Object} PromptDetails
 * @property {OidcScope[]} [missingOIDCScope]
 *
 * @typedef {Object} Prompt
 * @property {PromptDetails} details
 * @property {"login"|"consent"} name
 * @property {PromptReason[]} reasons
 *
 * @typedef {Object} Session
 * @property {String} accountId
 * @property {String} cookie
 * @property {String} uid
 *
 * @typedef {Object} ConsentPrompt
 * @property {Client} client
 * @property {Number} exp
 * @property {AuthRequest} params
 * @property {Prompt} prompt
 * @property {Session} session
 * @property {String} uid
 * @property {import("components/SignedInUser").UserInfo} userinfo
 */

/** @param {{ scope: OidcScope }} props */
const OidcScope = ({ scope }) => {
  switch (scope) {
    case "openid":
      return null
    case "profile": return (
      <List.Item>
        <List.Item.Meta
          avatar={<UserOutlined />}
          title="View your profile"
        />
      </List.Item>
    )
    case "email": return (
      <List.Item>
        <List.Item.Meta
          avatar={<MailOutlined />}
          title="View your email address"
        />
      </List.Item>
    )
    case "address": return (
      <List.Item>
        <List.Item.Meta
          avatar={<HomeOutlined />}
          title="View your address"
        />
      </List.Item>
    )
    case "phone": return (
      <List.Item>
        <List.Item.Meta
          avatar={<PhoneOutlined />}
          title="View your phone number"
        />
      </List.Item>
    )
    case "offline_access": return (
      <List.Item>
        <List.Item.Meta
          avatar={<SafetyOutlined />}
          title="Keep you signed in"
        />
      </List.Item>
    )
    default: return (
      <List.Item>
        <List.Item.Meta
          avatar={<QuestionOutlined />}
          title="Other"
          description={scope}
        />
      </List.Item>
    )
  }
}

/** @param {{ prompt: Prompt }} props */
const ConsentScopes = ({ prompt }) => {
  if (prompt.details?.missingOIDCScope) return (
    <List
      size="small" split={false}
      dataSource={prompt.details.missingOIDCScope}
      renderItem={(item) => <OidcScope scope={item} />}
    />
  )
  return null
}

/** @param {{ reasons: PromptReason[], client: Client }} props */
const ConsentMessage = ({ reasons, client }) => {
  if (reasons.includes("op_scopes_missing")) return (
    <Typography.Paragraph>
      When you allow this access,<br/>
      <Typography.Link href={client.client_uri} target="_blank" strong> { client.client_name } </Typography.Link>
      will be able to
    </Typography.Paragraph>
  )
  if (reasons.includes("consent_prompt")) {
    if (client.firstParty) return (
      <Typography.Paragraph>
        You were already signed in, confirm to access
        <Typography.Link href={client.client_uri} target="_blank" strong> { client.client_name } </Typography.Link>
      </Typography.Paragraph>
    )
    return (
      <Typography.Paragraph>
        You previously gave
        <Typography.Link href={client.client_uri} target="_blank" strong> { client.client_name } </Typography.Link>
        access, confirm to sign in again
      </Typography.Paragraph>
    )
  }
}

const Consent = () => {
  const data = /** @type {ConsentPrompt} */ (useLoaderData())
  const [ error, setError ] = useState("")
  const carouselRef = useRef(null)

  const confirmConsent = () => {
    // confirm consent first
    fetch(`/oidc/interaction/${data.uid}/confirm`, {
      method: "POST",
      redirect: "error"
    })
    .then(async response => {
      if (response.status === 200) {
        /** @type {{ redirectTo: String }} */
        const data = await response.json()
        console.log(data)
        if (data.redirectTo) {
          window.location.replace(data.redirectTo)
        }
      } else {
        const contentType = response.headers.get("content-type")
        if (contentType.includes("application/json")) {
          const data = await response.json()
          setError(data.error_description || data.error_detail || data.error)
        } else {
          console.log(await response.text())
          setError("Session has expired")
        }
        carouselRef.current?.goTo(1)
      }
    })
    .catch(err => {
      console.error(err)
      setError("Network error: failed to confirm access")
    })
  }

  return (
    <Container>
      <div className="auth-design">

      </div>
      <div className="auth">
        <Container.Header />
        <Container.Title level={4}>
          { data.client.client_name } wants to access your Web-Fuse Account
        </Container.Title>

        <SignedInUser userinfo={data.userinfo} />

        <Carousel ref={carouselRef}
          dots={false} infinite={false}
          touchMove={false} speed={400}
        >
          <div>
            <Space direction="vertical">
              <ConsentMessage client={data.client} reasons={data.prompt.reasons} />
              <ConsentScopes prompt={data.prompt} />

              <Button onClick={confirmConsent} type={!error ? "primary" : "default"}>Confirm</Button>
              <Link to="/session/end">
                <Button>Sign out</Button>
              </Link>

              { error &&
                <Button onClick={() => carouselRef.current?.next()} type="primary">View error</Button> }
            </Space>
          </div>
          <div>
            <Space direction="vertical">
              <Typography.Paragraph type="danger" style={{ textTransform: "capitalize" }}>{ error }</Typography.Paragraph>

              <Button href={data.client.initiate_login_uri || data.client.client_uri} type="primary">Restart login session</Button>

              <Button onClick={() => carouselRef.current?.prev()}>Return to request</Button>
            </Space>
          </div>
        </Carousel>

        <CancelLogin disabled={!!error} />
      </div>
    </Container>
  )
}

export default Consent
