import { Typography } from "antd"
import { Container, CancelLogin } from "../../components"
import { useLoaderData } from "react-router-dom"

const DefaultPrompt = () => {
  /** @type {Object} */
  const data = useLoaderData()

  return (
    <Container>
      <Container.Header />
      <Container.Title>Login error</Container.Title>

      <Typography.Paragraph>
        Login state not valid, cannot continue { data.client.client_name && `to ${data.client.client_name}` }
      </Typography.Paragraph>

      <CancelLogin />
    </Container>
  )
}

export default DefaultPrompt
