import { useState } from "react"
import { Button, Checkbox, ConfigProvider, Form, Input, Space, Typography } from "antd"
import isEmail from "validator/es/lib/isEmail"

import { LoadingOutlined } from "@ant-design/icons"
import { matchRoutes, useLoaderData, useNavigate } from "react-router-dom"
import { Container, CancelLogin } from "../../components/index.js"
import GReCaptchaV3, { captchaExecute } from "components/GReCaptchaV3.jsx"

const userRegexp = new RegExp("[a-zA-Z0-9-_]{3,20}")

const Login = () => {
  /** @type {Object} */
  const data = useLoaderData()
  const navigate = useNavigate()
  const [ error, setError ] = useState("")

  const [ loading, setLoading ] = useState(false)

  const handleLogin = async (values) => {
    setLoading(true)
    setError("")

    try {
      const captchaToken = await captchaExecute()
      // Does account authentication
      const loginResponse = await fetch(`/oidc/interaction/${data.uid}/login`, {
        method: "POST",
        redirect: "error",
        headers:{
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: new URLSearchParams(Object.assign({ captchaToken }, values))
      })

      // login was valid
      if (loginResponse.status === 200) {
        /** @type {{ redirectTo: String }} */
        const data = await loginResponse.json()

        if (data.redirectTo) {
          // Get new interaction from auth endpoint
          const authResponse = await fetch(data.redirectTo)
          if (authResponse.status === 200 && authResponse.redirected) {
            const location = new URL(authResponse.url)
            const match = matchRoutes([{ path: "/oidc/interaction/:session" }], location.pathname)
            if (match?.length > 0) {
              navigate(match[0].pathname, { replace: true })
            } else {
              // we probably still want to follow the location
              window.location.href = authResponse.url
            }
          } else {
            throw authResponse
          }
        }
      } else {
        throw loginResponse
      }
    } catch (err) {
      if (err instanceof Response) {
        const response = err
        const contentType = response.headers.get("content-type")
        if (contentType.includes("application/json")) {
          const data = await response.json()
          setError(`Login failed: ${data.error_description || "Authentication error"}`)
          return
        }
      }
      if (err instanceof SyntaxError) {
        console.warn("Could not parse JSON")
        return
      }
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <div className="auth-design">

      </div>
      <div className="auth">
        <Container.Header />
        <Container.Title>Sign in</Container.Title>
        { typeof data?.client?.client_name === "string" &&
          <Container.Subtitle>to continue to { data?.client?.client_name }</Container.Subtitle> }

        { error &&
          <Typography.Paragraph type="danger">{ error }</Typography.Paragraph> }

        <ConfigProvider theme={{
          components: {
            Form: {
              itemMarginBottom: 14
            }
          }
        }}>
          <Form onFinish={handleLogin}>
            <Form.Item name="login" initialValue={data.params.login_hint} rules={[{
              required: true,
              message: "Email address is required"
            }, {
              validator: (_rule, value) => {
                if (userRegexp.test(value) || isEmail(value)) return Promise.resolve()
                return Promise.reject(new Error("Enter a valid email address or username."))
              }
            }]}>
              <Input placeholder="Email or username" autoFocus={!data.params.login_hint} suffix={loading ? <LoadingOutlined /> : <span/>} />
            </Form.Item>
            <Form.Item name="password" rules={[{
              required: true,
              message: "Password cannot be empty"
            }]}>
              <Input.Password placeholder="********" autoFocus={!!data.params.login_hint} />
            </Form.Item>

            <Form.Item
              name="remember"
              valuePropName="checked"
              tooltip="Remember login after browser session closed, for up to 30 days" // or one year? check oauth ttl
              initialValue={true}
            >
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" type="primary" loading={loading}>Sign in</Button>
            </Form.Item>

            <GReCaptchaV3 action="identity-login" />
          </Form>
        </ConfigProvider>

        <Space align="baseline" style={{ justifyContent: "space-between", display: "flex" }}>
          <CancelLogin />
          <Button href={data.registerUri}>no account? register here</Button>
        </Space>
      </div>
    </Container>
  )
}

export default Login
