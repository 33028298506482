import { createBrowserRouter, RouterProvider } from "react-router-dom"

import PageRoot from "./pages/root"
import SessionNotFound from "./pages/SessionNotFound"
import EndSession from "pages/EndSession"
import EndSessionSuccess from "pages/EndSessionSuccess"
import { setFooter } from "shared/pageFooter"
import prompt from "pages/prompt"

setFooter()

const router = createBrowserRouter([
  {
    path: "/",
    element: <PageRoot />,
  }, {
    path: "/dialog",
    children: prompt,
    errorElement: <SessionNotFound />
  },
  EndSession,
  EndSessionSuccess
])

const App = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}

export default App
