import { useEffect } from 'react'

/** @type {any} grecaptcha type */
const globalWin = window

const siteKey = "6LfgQ4QaAAAAAAUiAwZJ974TVCpd3F9a-ardtAsY"
let captchaReady = false

const handleLoaded = () => {
  globalWin.grecaptcha.ready(() => {
    captchaReady = true
  })
}

/** @param {{ action: String }} props */
const GReCaptchaV3 = ({ action }) => {
  useEffect(() => {
    if (typeof action !== "string") window.alert("no captcha action")

    globalWin.captchaOnLoad = handleLoaded

    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js?onload=captchaOnLoad&render=" + siteKey
    script.addEventListener("load", handleLoaded)
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [ action ])

  return (
    <div
      className="g-recaptcha"
      data-sitekey={siteKey}
      data-action={action}
      data-size="invisible"
    />
  )
}

/** @returns {Promise<String>} */
export const captchaExecute = async (action = "submit") => {
  if (!captchaReady) return Promise.reject(new Error("reCaptchaV3 has not loaded"))
  return globalWin.grecaptcha.execute(siteKey, { action })
}

export default GReCaptchaV3
