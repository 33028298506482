import { UserOutlined } from "@ant-design/icons"
import { Avatar, Button, Space, Typography } from "antd"

/**
 * https://openid.net/specs/openid-connect-basic-1_0.html#StandardClaims
 * @typedef {Object} UserInfo
 * @property {String} email
 * @property {String} preferred_username
 * @property {String} [name]
 *
 * @typedef {Object} Props
 * @property {UserInfo} userinfo
 * @property {Boolean} [allowChangeUser=true]
 */

/** @param {Props} props */
const SignedInUser = ({ userinfo, allowChangeUser = true }) => {
  const name = userinfo.name || userinfo.preferred_username

  return (
    <Space align="baseline" style={{ marginLeft: 4 }}>
      {/* TODO: add profile picture here in the future */}
      <Avatar icon={<UserOutlined />} style={{ backgroundColor: "#25b1ef", marginBottom: 4 }} size={28} />
      <Typography.Paragraph>{ userinfo.email }</Typography.Paragraph>
      { name &&
        <Typography.Paragraph>({ name })</Typography.Paragraph> }

      {/* TODO: create button to change user/session */}
      { allowChangeUser &&
        <Button size="small" disabled style={{ marginLeft: 12 }}>Change</Button> }
    </Space>
  )
}

export default SignedInUser
